<!--
  Form used to save, add and edit searches
-->

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
      v-if="agendaDialogFormSavedSearchesVisible"
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('query.save') }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon class="mt-n2 mr-n2" @click="hideForm()">
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            valid
            @submit.prevent="submitForm('form')"
          >
            <v-text-field
              class="mb-3"
              v-model="form.title"
              :rules="titleRules"
              :label="$t('agenda.form.title')"
              counter
              maxlength="50"
              required
            />

            <v-switch
              class="mt-n1"
              v-model="form.includeInWebcal"
              label="Vergaderingen synchroniseren met eigen agenda"
            />

            <v-checkbox
              class="mt-n3"
              v-model="form.sendUpdatesPerEmail.activitiesOverview.enabled"
            >
              <template v-slot:label>
                <div>
                  Vergaderplanning e-mailen (op gekozen dagen)
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small>mdi-help-circle</v-icon>
                    </template>
                    Ga naar instellingen achter je naam en kies de dag(en) en tijdstip waarop je het overzicht ontvangt.
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>

            <v-checkbox
              class="mt-n3"
              v-model="form.sendUpdatesPerEmail.activitiesFrequent.enabled"
            >
              <template v-slot:label>
                <div>
                  Tussentijdse wijzigingen e-mailen (checkt elke 30 minuten)
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small>mdi-help-circle</v-icon>
                    </template>
                    Controleert voor nieuwe, gewijzigde en verwijderde vergaderingen
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>

            <div class="text-end mr-n4 mb-n3">
              <v-btn class="mr-2" @click="cancelForm('form')">
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm('form')"
              >
                {{ $t('generic.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AgendaSaveSearchesForm',

  props: {
    visible: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      valid: false,
      dialog: true,
      form: {
        title: null,
        includeInWebcal: false,
        sendUpdatesPerEmail: {
          // TODO: Reset to true after full implementation of agenda functionality
          dailyChanges: false,
          activitiesFrequent: {
            enabled: false,
          },
          activitiesOverview: {
            enabled: false,
          },
        },
      },
      titleRules: [
        (v) => !!v || 'Geef de zoekopdracht een titel',
        (v) => (v && v.length <= 50) || 'Maximaal 50 tekens',
      ],
      busy: false,
      disabled: false,
    };
  },

  computed: {
    ...mapGetters({
      currentSearch: 'agendaCurrentSearch',
      agendaDialogFormSavedSearchesVisible:
        'agendaDialogFormSavedSearchesVisible',
    }),
    // Merge the current form with the current search
    search() {
      // We need to make a copy of the currentSearch
      // to avoid manipulating the store directly which causes for example
      // the displayed search item to change without even being saved.
      return { ...this.form, ...this.currentSearch };
    },

    setFormData() {
      this.resetForm();

      // if search has been saved repopulate the form with the values from saved search
      if (this.currentSearch.id) {
        this.onSetFormData();
      }
      return null;
    },
  },

  methods: {
    onSetFormData() {
      this.form.title = this.currentSearch.title;
      this.form.includeInWebcal = this.currentSearch.includeInWebcal;
      this.form.sendUpdatesPerEmail.activitiesOverview.enabled = this
        .currentSearch.sendUpdatesPerEmail.activitiesOverview.enabled;
      this.form.sendUpdatesPerEmail.activitiesFrequent.enabled = this
        .currentSearch.sendUpdatesPerEmail.activitiesFrequent.enabled;
      this.form.sendUpdatesPerEmail.dailyChanges = this
        .currentSearch.sendUpdatesPerEmail.dailyChanges;
    },
    /**
     * Hides/closes the form
     */
    hideForm() {
      this.resetForm();
      // this.$refs[formName].resetFields();
      this.$store.dispatch('agendaSetDialogFormSavedSearchesVisible', false);
    },

    /**
     * Resets the form and empties all form elements
     */
    resetForm() {
      this.form = {
        title: null,
        includeInWebcal: false,
        sendUpdatesPerEmail: {
          // TODO: Reset to true after full implementation of agenda functionality
          dailyChanges: false,
          activitiesFrequent: {
            enabled: false,
          },
          activitiesOverview: {
            enabled: false,
          },
        },
      };
    },

    /**
     * Cancel the form
     */
    cancelForm(formName) {
      this.hideForm(formName);
    },

    /**
     * Submits the form
     *
     * @param evt Event of the submit button
     */
    submitForm(formName) {
      if (this.$refs.form.validate()) {
        this.busy = true;
        this.disabled = true;
        this.search.title = this.form.title;
        this.search.includeInWebcal = this.form.includeInWebcal;
        this.search.sendUpdatesPerEmail = this.form.sendUpdatesPerEmail;
        this.$store.dispatch('agendaSaveSearch', this.search).then(
          () => {
            this.busy = false;
            this.disabled = false;
            this.hideForm(formName);
            this.resetForm();
          },
          () => {
            this.busy = false;
            this.disabled = false;
            this.hideForm(formName);
          },
        );
      } else {
        // Don't use reset overhere because it may result in resetting unsaved changes hence
        // UX issues.
        return null;
      }
      return null;
    },
  },

  beforeUpdate() {
    // this is to populate the form in case of editing a saved search.
    this.$nextTick(() => {
      // You can't call computed values as functions.
      // See https://stackoverflow.com/questions/48901546/how-to-write-this-function-as-a-computed-property-in-vue
      // eslint-disable-next-line no-unused-expressions
      this.setFormData;
    });
  },
};
</script>

<style scoped lang="scss">
.spinner {
  top: 2px;
  position: relative;
  left: 4px;
}
</style>

<style lang="scss"> // Carefull, block not scoped
.direct-label {
  margin-top: -20px;
  .el-form-item__label {
    margin-top: 10px;
    line-height: 20px;
  }
}
</style>
